<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.first_name)"
          :variant="`light-${resolveUserRoleVariant(userData.user_type)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.first_name+' '+userData.last_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Change</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Student Code"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="userData.code"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="First Name"
            label-for="first-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.first_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Last Name"
            label-for="last-name"
          >
            <b-form-input
              id="last-name"
              v-model="userData.last_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Phone Number"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="userData.phone_number"
              type="tel"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userData.city"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Account Status"
            label-for="user-status"
          >
            <v-select
              v-model="userData.isActive"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Type"
            label-for="referral_code"
          >
            <b-form-input
              id="user_type"
              v-model="userData.user_type"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: gender -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gender"
            label-for="user-gender"
          >
            <v-select
              v-model="userData.gender"
              :options="genderOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-gender"
            />
          </b-form-group>
        </b-col>        

        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Referral Code"
            label-for="referral_code"
          >
            <b-form-input
              id="referral_code"
              v-model="userData.referral_code"
              type="text"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateAccount"
    >
      Save Changes
      <b-spinner
        v-show="loading"
        variant="light"
        small
      />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'STUDENT', value: 'STUDENT' },
      { label: 'TUTOR', value: 'TUTOR' },
      { label: 'STAFF', value: 'maintainer' }
    ]

    const statusOptions = [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' },
    ]

    const genderOptions = [
      { label: 'Female', value: 'Female' },
      { label: 'Male', value: 'Male' },
    ]

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      props.userData.profile_avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      genderOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      details: this.userData,
      loading: false,
    }
  },
  methods: {
    updateAccount() {
      this.loading = true
      const details = {
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        email: this.userData.email,
        phone_number: this.userData.phone_number,
        gender: this.userData.gender,
        code: this.userData.code,
        user_type: this.userData.user_type,
        isActive: this.userData.isActive
      }
      this.$http.put(`${this.$url}/student/${this.userData.id}`, details)
        .then(response => {
          if (response.data.status) {
            this.userData = response.data.data
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              // window.location.reload()
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
